import lodash from 'lodash';
import { DateTime } from 'luxon'
import Excel from 'exceljs';
import he from 'he';
// https://vuejsprojects.com/epic-spinners
import {AtomSpinner} from 'epic-spinners'
// https://vuejsprojects.com/vue-google-charts
// https://github.com/devstark-com/vue-google-charts
import { GChart } from 'vue-google-charts' 
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import katex from 'katex';
import 'katex/dist/katex.min.css';

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      _: lodash,
      DateTime: DateTime,
      ExcelJS: Excel,
      he: he,
      GChart,
      AtomSpinner,
      katex
    }
  };
});